import { CCApiService } from './api-service';
import { Application, ApplicationState, UIConfig } from './interfaces';

export class ApplicationsService {
  static ApiService: CCApiService;
  static applicationsAPI: string;
  static apiBasePath: string;
  static customerId: string;

  static init(config: UIConfig, customerId: string) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.apiBasePath = config.apiBasePath;
    this.customerId = customerId;
    this.applicationsAPI = `${this.apiBasePath}/customers/${this.customerId}/solutions`;
  }

  static async getApplications(): Promise<Application[]> {
    return this.ApiService.performFetch(this.applicationsAPI).then(apps => {
      return apps.map(app => {
        if (app.state === ApplicationState.ACTIVE && !app.datasourceCount) {
          app.state = ApplicationState.READY_TO_CONFIGURE;
        }
        return app;
      });
    });
  }

  static async createApplication(application: Application): Promise<void> {
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(application),
    };
    return this.ApiService.performFetch(this.applicationsAPI, fetchOptions);
  }

  static async editApplicationUserRoles(application: Application, roles: any): Promise<void> {
    const updateAppApi = `${this.apiBasePath}/customers/${application.customerId}/solutions/${application.id}/users`;
    const fetchOptions = {
      method: 'PATCH',
      body: JSON.stringify(roles),
    };
    return this.ApiService.performFetch(updateAppApi, fetchOptions);
  }

  static async deleteApplication(id: string): Promise<void> {
    const url = `${this.applicationsAPI}/${id}`;
    const fetchOptions = {
      method: 'DELETE',
    };
    return this.ApiService.performFetch(url, fetchOptions);
  }
}
